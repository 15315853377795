.dropdown {
    position: fixed; /* Fix position to the viewport */
    top: 14.5%;
    left: 0;
    //margin-left: 24px;
    width: 100%;
    height: 100%;
    background: #87b6d6cd; /* Black background with some opacity */
    padding: 50px;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    //z-index: 999; /* High z-index to ensure it's on top of other elements */
    text-decoration: none;
  }
  
  
  .dropdown li {
    padding: 8px 16px;
    border-bottom: 1px solid #ddd;
    font-size: 36px;
    font-style: "orbit", Arial, Helvetica, sans-serif !important;
    margin: 0;
    
    list-style-type: none;
    text-decoration: none !important;
  }
  
  .dropdown li:last-child {
    border-bottom: none;
  }
  
  .dropdown li a {
    color: rgb(255, 255, 255);
    text-decoration: none !important;
    font-weight: bold;
    display: block;
  }

  .no-scroll {
      overflow: hidden;
  }

  @media (min-width: 991px) {
      .dropdown {
          display: none;
      }
      .no-scroll {
          overflow: scroll;
      }
  }


  @media (max-width: 410px) {
    .dropdown {
        top: 13%;
    }
}