@import 'global.scss';

#top-subheading {
  margin-top: 20px;
}
.home-container {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  // margin-top: 8px;
}


.not-in-browser {
  display: none;
}

.home-body {
  font-size: 30px;
  max-width: 80%;
  margin-bottom: 50px;
  // font-weight: bold;
}

#home-img-container {
  display: flex;

  justify-content: center;
}

// #home-img {
//   width: 80vw;
//   justify-content: center;

//   height: 30vh;
//   object-fit: cover;
//   object-position: 25% 25%;
// }

#home-heading {
  margin: 28px;
  font-style: 'Unica One', Arial, Helvetica, sans-serif;
  text-align: center;
  // display: none;
}
.middle-img-home {
  width: 24.75%;
  box-sizing: border-box;
  border-top: #87b7d6;
  border-bottom: #87b7d6;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
  justify-content: space-between;
}
.tile-row {

  box-sizing: border-box;
  justify-content: space-between;
  height: 200px;
}
.body-img-container {
  display: flex;
  flex-wrap: nowrap;
  align-self: center;
  justify-content: center;
  gap: 40px;
}

.home-subheading {
  font-family: 'Unica One', Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 36px;
  width: 70%;
  margin-right: 50px;
  margin-left: 50px;
  color: $primary1-color;
}

@media (max-width: 400px) {
  #page-container {
    border: 1px solid black;
  }

  .body-img-container {
    display: flex;
    flex-direction: column;
  }
  .middle-img-home {
    width: 75%;
    box-sizing: border-box;
    border-top: #87b7d6;
    border-bottom: #87b7d6;
    margin-bottom: 20px;
    margin-left: 2px;
    margin-right: 2px;
    align-self: center;
  }

  .not-in-browser {
    display: block;
  }
  .not-in-phone {
    display: none;
  }
  .home-subheading {
    font-family: 'hind', Arial, Helvetica, sans-serif;
    font-weight: bold;
    width: 75%;
    margin-right: 20px;
    margin-left: 20px;
    font-size: 32px;
    text-align: center;
  }
  .home-body {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
  }
}
