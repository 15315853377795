@import 'global.scss';
.nav-link-custom {
  text-decoration: none;
  
}
.nav-link-custom:hover {
  color: $primary1-color
}

@media (min-width: 991px) {
  .navbar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #hamburger-menu {
    display: none;
  }
  .navbar-custom {
    //position: fixed !important;
    top: 0 !important;
    width: 100% !important;
  }
  .navbar-collapse {
    z-index: 1001; /* Set the z-index value to be higher than page content */
  }

  .navbar {
    padding: 0px;

    // border: 4px solid  #fcc3b9; /* First border color */
    // box-shadow: 0 0 0 4px #fff9da, /* Second border color */ 0 0 0 8px #87b7d6; /* Third border color */
    background-color: white;
    position: sticky;
    top: 0px;
    // margin: 4px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    width: 100%;
    //z-index: 1001;
    // gap: 3rem;
    // height: 180px;
  }
  .navbar-brand {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .navbar-title {
    color: $primary1-color;
    font-size: 72px;
    font-family: 'Unica One', Arial, Helvetica, sans-serif;
    //font-family: 'Cinzel';
    padding: 0;
    margin-top: 25px;
  }
  .navbar-subtitle {
    color: rgb(57, 56, 56);
    font-size: 16px;
    margin-top: -20px;
    font-family: 'Orbit', Arial, Helvetica, sans-serif;
    //font-family: 'Unica_One';
  }
  .ml-auto {
    display: flex;
    gap: 60px;
    font-weight: 500;
    font-family: 'Hind', Arial, Helvetica, sans-serif;
    //font-family: 'Unica_One';
    //font-family: "cinzel";
    align-items: stretch;
    //font-family: 'Cinzel';
  }
  .nav-link-custom {
    color: $pink;
    font-size: 24px;
    padding: 0px;
  }
}

//when under 991 do this:

#high {
  border-bottom: 6px solid #fcc3b9;
  box-sizing: border-box;
  width: 100%;
}

#med {
  border-bottom: 6px solid #fff9da;
  box-sizing: border-box;
  width: 100%;
}

#low {
  border-bottom: 6px solid #87b7d6;

  box-sizing: border-box;
  width: 100%;
}
@media (max-width: 991px) {
  .navBar {
    display: flex;
    padding: 0px;
    justify-content: space-between;
  }
  // .navbar-collapse {
  //   background-color: rgb(6, 6, 6); /* set the background color */
  //   border-radius: 5px; /* add some border radius */
  //   margin-top: 2rem; /* add some margin */
  // }
  .navbar-title {
    color: $primary1-color;
    font-size: 36px;
    font-family: 'Unica One', Arial, Helvetica, sans-serif;
    //font-family: 'Cinzel';
    padding: 0;
    margin-top: 25px;
    margin-left: 40px;
  }
  .navbar-subtitle {
    color: rgb(57, 56, 56);
    font-size: 16px;
    margin-top: -10px;
    font-family: 'Orbit', Arial, Helvetica, sans-serif;
    //font-family: 'Unica_One';
  }
  .ml-auto {
    display: none;
  }
  .navbar-container {
    display: flex;
    //border: 2px green solid;
    //flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  #hamburger-menu {
    display: inline-block;
    align-self: center;
    justify-self: center;
    margin-right: 80px;
    //box-sizing: border-box;
  }

  .nav-link {
    display: block; /* change the display to block */
    margin: 0 auto 1rem; /* center the links */
    text-decoration: none;
  }

}

@media (max-width: 400px) {
  // .ml-auto {
  //   display: flex;
  //   gap: 20px;
  //   font-weight: 500;
  //   font-family: 'Hind', Arial, Helvetica, sans-serif;
  //   //font-family: 'Unica_One';
  //   //font-family: "cinzel";
  //   align-items: stretch;

  // }
  .navbar {
    padding-bottom: 0;
  }
  .ml-auto {
    display: none;
  }
  .navbar-brand {
    margin: 0;
  }
  .navbar-title {
    color: $primary1-color;
    font-size: 24px;
    font-family: 'Unica One', Arial, Helvetica, sans-serif;
    //font-family: 'Cinzel';
    padding: 0;
    margin-left: 20px;
  }
  .navbar-subtitle {
    font-size: 8px;
    margin-top: -8px;
  }

  #hamburger-menu {
    margin-right: 35px;
  }
}
