@import 'global.scss';

//displays the the two times
#display-info {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  //margin-top: -10px;
  text-align: center;
  flex-shrink: 1;
  
  
}
//displays entire content is horizontal view
.findus-display {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: rgba(84, 141, 172, 0.249);
  width: auto;
  text-align: center;

  //flex-grow: 1;
  ;
}
.background {
  //flex-grow: 0;
  background-image: url(../assets/images/city-sunrise-2.jpg);
  // background-image: url(../assets/images/home-option1.png);
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  
  
}

#find-us-heading {
  font-size: 32px;
  margin: 20px;
  text-align: center;
  align-self: center;
  //color: rgb(255, 153, 0);
}
#find-us-subheading {
  font-size: 48px;
  margin-top: 10px;
  text-align: center;
  align-self: center;
  justify-self: center;
  font-family: 'Unica One';
  color: whitesmoke
  //align-self: flex-start;
}

#at-the-text {
  font-size: 30px;
}
.subHeading {
  font-size: 38px;
  color: whitesmoke;
  font-weight: bold;
}
#right-upper {
  height: 150px;
}
#left-large {
  height: 400px;
}

.pictures-container {
  display: flex;
  gap: 10px;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 50px;
  flex-wrap: wrap;
  // background-image: url('../assets/images/background-spanish-1.png');
  // background-repeat: repeat;
}
.right-half-pictures {
  width: calc(
    70% - 20px
  ); /* Assuming 50% space for the right container and accounting for half the gap */
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between upper image and bottom row */
}

.upper-right-half {
  width: 70%;
  height: auto;
  display: block;
}
#left-large {
  width: calc(
    30% - 20px
  ); /* Assuming 50% space for large image and accounting for half the gap */
  display: block;
  max-width: 100%;
  height: auto;
}
#right-upper {
  width: calc(100%);
  height: auto;
}
.bottom-row {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between small images */
}

.small-pics {
  width: calc(
    33.33% - (2 / 3 * 20px)
  ); /* Each image takes up a third, minus the gap */
  display: block;
  max-width: 100%;
  height: 100%;
}

@media (max-width: 1200px) {
  #left-large {
    display: none;
  }
  .right-half-pictures {
    width: auto;
  }
}

@media (max-width: 700px) {
  .pictures-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .subHeading {
    margin-bottom: 0px;
  }
  #display-info {
    display: flex;
    justify-content: center;
    margin-top: -30px;
    flex-direction: column;
    margin: 10px;
  }
  #left-large {
    width: 100%;
  }
  .right-half-pictures {
    width: 100%;
  }
}
@media (max-width: 460px) {
  body {
    height: fit-content;
  }
  .findus-display {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 40px;
    margin-left: 40px;
    background-color: rgba(255, 255, 255, 0);
   
  }
  .background {
  background-image: url(../assets/images/city-sunrise.png);
  }
  #find-us-subheading {
    font-size: 54px;
  }
  .pictures-container {
    display: none;
    flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
  }
  #display-info {
    display: flex;
    justify-content: center;
    margin-top: -30px;
    flex-direction: column;
    margin: 10px;
  }
  .subHeading {
    font-size: 32px;
    margin: 5px;
  }

  #left-large {
    width: 100%;
  }
  .right-half-pictures {
    width: 100%;
  }
  .small-pics {
    width: 100%;
    flex-direction: column;
  }
  .bottom-row {
      display: none;
      flex-direction: column;
  }
}
