@import 'global.scss';

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #87b7d6;
  margin: 6px 0;
  transition: 0.4s;
}

.hamburger-icon-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}



.change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
  }
  
  /* Fade out the second bar */
  .change .bar2 {opacity: 0;}
  
  /* Rotate last bar */
  .change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
  }
  @media (max-width: 400px) {
      
  }