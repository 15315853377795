@import 'global.scss';


#services-heading {
  margin-top: 25px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-family: 'Unica One', Arial, Helvetica, sans-serif;
 
}

.services-subheading {
  font-family: 'Unica One' !important;
  margin: 10px;
  text-align: center;
  color: $primary1-color;
  font-weight: bold;
}

.services-body {
  font-size: 24px;
  margin: 20px;
  color: rgb(58, 58, 58);
  text-align: center;
  //font-family: 'hind';
}

@media (max-width: 400px) {
  #services-heading{
      margin-bottom: 0px;
  }
}