@import 'global.scss';

#heading-email {
  font-size: 32px;
  font-family: 'Unica One', Arial, Helvetica, sans-serif;
  text-align: center;
  margin-top: 25px;
}

#subheading-email {
  font-size: 48px;
  text-align: center;
}

@media (max-width: 460px) {
    #heading-email {
        font-size: 20px;
    }
    #subheading-email {
        font-size: 28px;
       
      }
}
