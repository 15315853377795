$primary1-color: #87b7d6;
$primary2-color: rgb(255, 153, 0);
$secondary-color: rgb(57, 56, 56);
$pink: #e68e93;

//#f04535;



html {
  margin: 0;
  
  width: 100%;

}

body {
  // min-height: 100%;
  overflow-x: hidden;
  
}

#page {
  min-height: 100%;
  box-sizing: border-box;
}

#root {
  min-height: 100%;
  
}
Button {
  background-color: #fcc3b9;
}
.btn-primary {
  -bs-btn-color: #fff;
    /* --bs-btn-bg: #0d6efd; */
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #87b7d6;
    --bs-btn-hover-border-color: #87b7d6;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #87b7d6;
    --bs-btn-active-bg: #87b7d6;
    --bs-btn-active-border-color: #87b7d6;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
button {
  margin: 20px;
  background-color: white;
  color: #87b7d6;
  padding: 15px 32px;
  font-weight: bold;
  // background-image: url('../assets/images/background-spanish-1.png');

}
.btn {
  margin: 20px;
  font-weight: bold;
  background-color: white;
  color: #87b7d6;
  border: 3px solid #87b7d6;
  padding: 15px 32px;
}

// @font-face {
//   font-family: 'Orbit'; /* you can name it anything you want */
//   // src: url('../assets/fonts/Orbit/Orbit-Regular.ttf'); /* path to your font file */
// }

@font-face {
  font-family: 'Saira_Condensed'; /* you can name it anything you want */
  src: url('../assets/fonts/Saira_Condensed/SairaCondensed-Light.ttf'); /* path to your font file */
}




// @font-face {
//   font-family: 'Unica_One'; /* you can name it anything you want */
//   src: url('../assets/fonts/Unica_One/UnicaOne-Regular.ttf'); /* path to your font file */
// }


h2 {
  font-family:  Arial, Helvetica, sans-serif;
  //font-family: 'Unica_One';
}
h3 {
  font-family: "orbit", Arial, Helvetica, sans-serif;
  
  //font-family: 'Unica_One';
}
p  {
  font-family: "Saira_Condensed", Arial, Helvetica, sans-serif;
  
  //font-family: 'Unica_One';
  //font-family: "cinzel";
}

//styling border for page content (does not include navBar)




.outer-container {
  border: 6px solid #87b7d6;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  position: sticky;
}
.middle-container {
  border: 6px solid #fff9da;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
}

.inner-container {
  border: 6px solid #fcc3b9;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  //overflow: auto;
}

.inner-inner-container {
  border: 6px solid #87b7d6;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content-container {
  border-top: 6px solid #87b7d6;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
h5 {
  //font-family: 'Unica_One';
  font-size: 24px;
  font-family: 'Orbit';
}


#top-border {
  flex-grow: 1;
  box-sizing: border-box;

}

#middle-border {
  border-top: 6px solid #fcc3b9;
  box-sizing: border-box;
  width: 100%;
}

#bottom-border {
  border-top: 6px solid #fff9da;
  box-sizing: border-box;
  width: 100%;
}

#page {
  
  // border-left: 4px solid #87b7d6; /* Left first border color */
  // border-right: 4px solid #87b7d6; /* Right first border color */
  // border-bottom: 4px solid #87b7d6; /* Bottom first border color */
  // box-shadow: inset 4px 0 0 0 #fff9da,
  //   /* Left second border color */ 
  //   inset 4px 0 0 0 #fff9da,
  //   /* Right second border color */ 
  //   inset 8px 0 0 0 #fcc3b9,
  //   /* Left third border color */ 
  //   inset 8px 0 0 0 #fcc3b9,
  //   /* Right third border color */ 
  //   inset 0 4px 0 0 #fff9da,
  //   /* Bottom second border color */ 
  //   inset 0 8px 0 0 #fcc3b9; /* Bottom third border color */
  // // margin-left: 8px;
}
.img-container {
  overflow: hidden;
}
.top-img {
  width: 100%;
  box-sizing: border-box;
  border-top: #87b7d6;
  border-bottom: #87b7d6;
}

.container {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  // margin-top: 8px;
  // border-top: 4px solid #fcc3b9; /* First border color */
  // box-shadow: 0 -4px 0 0 #fff9da, /* Second border color */ 0 -8px 0 0 #87b7d6; /* Third border color */
}

.page-container {
  // margin-top: 188px;
  box-sizing: border-box;
  overflow: auto;
  flex-grow: 1;
  //display: flex;
  // margin-left: 8px;
  // margin-right: 8px;
}




@media (max-width: 450px) {
 
  .content-container {
    border-top: 6px solid #87b7d6;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }

  .page-container {
    flex-grow: 1;
  }

  body {
    width: fit-content; 
    margin: 0;
    overflow-x: hidden; 
  }

}