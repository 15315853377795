@import "global.scss";
.contact-heading {
    font-family: 'Unica One', Arial, Helvetica, sans-serif;
    color: $primary1-color;
}
#contact-content-container {
    display: flex;
    flex-direction: row;
}


.contact-label-heading {
    font-family: 'Unica One', Arial, Helvetica, sans-serif;
    
}
//border for inputs
.form-control {
    border: 1px solid;

}

@media (max-width: 400px) {

}