// Card.module.scss
//@import '../../styles/variables';

.card {
  width: 100%;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  &Image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  &Content {
    padding: 1rem;

    &Title {
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }

    &Description {
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }

    &Price {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    &QuantityContainer {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      gap: 10px !important;

      .quantityButton {
        //background-color: $primary-color;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 30px;

        &:hover {
          //background-color: darken($primary-color, 10%);
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .quantityInput {
        width: 3rem;
        text-align: center;
        margin: 0 0.5rem;
      }
    }

    .addItemButton {
      //background-color: $primary-color;
      color: #fff;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;
      width: 100%;

      &:hover {
        //background-color: darken($primary-color, 10%);
      }
    }
  }
}